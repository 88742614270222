body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container {
  margin-right: 0 !important;
  margin-left: -20 !important;
  padding-left : -20 !important;  
  padding-right : 0 !important;
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left : 0 !important;  
  padding-right : 0 !important;
}
.wrap{
  background-image:url(http://charlessuits.org/sites/MrSuits.net/charlessuits/imb/Wines.jpg);
  background-size: cover;
  height: calc(100% + 50px);
}
@import-normalize;
