body {
  font-family: "Open Sans", sans-serif;
  margin: 0 !important;
  padding: 0 !important;
  background-image: linear-gradient(to right, #bcb9bd, #6c6b6e, #999999);
}

.card-title {
  font: 500 1.25rem "Lobster", cursive;
}

.card-img-overlay .card-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
.container {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left : 0 !important;  
  padding-right : 0 !important;
}


h1, h2, h3, .card-body>.card-title {
  font-family: "Lobster", cursive;
}

.card-title {
  font-weight: 500;
  font-size: 1.25rem;
}

h2 {
  color: #3046C5;
}

.card-img-overlay .card-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.row-content {
  padding: 10px 0;
  border-bottom: 1px ridge;
  min-height: 100px;
 }

.site-footer {
  background-image: linear-gradient(#0f0420, white);
  padding: 0;
}

.jumbotron {
  margin: 0;
  padding: 30px;
  background-image: linear-gradient(to right, #dbaf88, #b9905a, #cf8a4d);
  color: white;
}

.jumbotron h2 {
  color: #CEC8FF;
}
.navbar-light {
  background-image: linear-gradient(to right, #213031, #1c3c44, #363841);
}

.navbar-dark {
  background-image: linear-gradient(to right, #646364, #42403e, #2c2a2c);
}
.nav-link{
  font: 15px Arial, sans-serif;
  color: #f1e2e2 !important;
}
div.dropdown-menu.show {
  background-color: #575151; 
}
a.dropdown-item {
  color: #ffffff;   
}